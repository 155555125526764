import React from 'react';
import styled from 'styled-components';

import Container from '../components/Container';
import Button from '../components/Button';
import PageBase from '../components/PageBase';

import aboutUsImage from '../images/about-us.jpg';
import aboutUsPractiveVisionImage from '../images/about-us-practive-vision-image.svg';
import aboutUsTailoredFinancialPlanningImage from '../images/about-us-client-focused-advice-image.svg';
import aboutUsClientFocusedAdviceImage from '../images/about-us-tailored-financial-planning-image.svg';
import aboutUsProfessionalAdviceImage from '../images/about-us-professional-advice-image.svg';

export default function AboutUs() {
	return (
		<PageBase title="About us">
			<StyledFirstSection>
				<div className="about-us">
					<Container>
						<div className="about-us-content">
							<h3>About us</h3>
							<p>
								NEO Financial Solutions is an Australian Financial Services Licensee (AFSL). The primary focus is to provide our financial
								planners with the tools and services, which enable them to grow their businesses and do what they do best – provide high
								quality financial planning advice, tailored to their client’s specific situation.
							</p>
							<p>We are an adviser-led AFSL that strives to ensure you have a compliant, efficient, and profitable business.</p>
						</div>
						<img
							data-sal="fade"
							data-sal-delay="0"
							data-sal-duration="1000"
							data-sal-easing="ease-in-out"
							src={aboutUsImage}
							alt="About us"
						/>
					</Container>
				</div>
			</StyledFirstSection>
			<StyledSecondSection>
				<Container>
					<h3>Let's have a chat!</h3>

					<div className="chat-buttons">
						<Button to="/contact" value="Send us a message" colorScheme="light" size="big" classname="send-us-a-message-btn" />
						<Button href="tel:+610894447494" value="Call us (08) 9444 7494" colorScheme="light" size="big" className="call-us-btn" />
					</div>
				</Container>
			</StyledSecondSection>
			<StyledThirdSection>
				<Container>
					<div className="benefit-header">
						<h2>Why Choose NEO?</h2>
						<div className="why-choose-neo-container">
							<p>
								NEO was established in 2010 to focus on quality advisers that put their clients first. The Approved Product List is product
								agnostic and research based so that advisers can tailor to the client needs and provide financial advice that is specific to
								each client.{' '}
							</p>
							<p>
								NEO was established in 2010 to focus on quality advisers that put their clients first. The Approved Product List is product
								agnostic and research-based so that advisers can tailor to the clients needs and provide financial advice that is specific
								to each client. We are different because we not only provide licensee solutions and partnerships but ensure that we can
								offer a beneficial succession plan for you and your clients, all backed by market leading technology.
							</p>
						</div>
					</div>
					<div className="benefit invert">
						<img
							data-sal="fade"
							data-sal-delay="400"
							data-sal-duration="1000"
							data-sal-easing="ease-in-out"
							src={aboutUsClientFocusedAdviceImage}
							alt="About us"
						/>
						<div className="benefit-content">
							<h3>
								Licensing &<br /> Administration
							</h3>
							<p>
								Licensing and administration is the foundation of your partnership with NEO. We understand how time-consuming admin is and
								how stressful changing Licensee can be so our aim is to minimise your business disruption. Our application process has been
								designed to reduce as much hassle as possible and ensure a smooth transition.
							</p>
							<p className="benefit-list-label">NEO licensing includes the following: </p>
							<ul className="benefit-list">
								<li>Fixed 100% Remuneration structure</li>
								<li>Competitive Professional Indemnity Insurance</li>
								<li>Induction, coaching and training upon joining</li>
								<li>Detailed operations manual</li>
								<li>Broad APL</li>
								<li>Full Xplan Templates and Support</li>
							</ul>
						</div>
					</div>
					<div className="benefit">
						<img
							data-sal="fade"
							data-sal-delay="600"
							data-sal-duration="1000"
							data-sal-easing="ease-in-out"
							src={aboutUsPractiveVisionImage}
							alt="About us"
						/>
						<div className="benefit-content">
							<h3>
								Practice Vision & <br /> Design
							</h3>
							<p>
								Practice Vision and Design is a one on one process whereby we work with your business on several different levels depending
								on your requirements, to provide additional growth. The areas covered below can be accessed, delivered, and profited from,
								to add additional value to your business. At NEO we emphasise building your business to increase the impact you have on your
								clients.
							</p>
							<ul className="benefit-list">
								<li>Marketing</li>
								<li>Web design</li>
								<li>Pricing Services</li>
								<li>Mortgage Broking and Lending</li>
								<li>Practice Development</li>
								<li>Business Strategy</li>
							</ul>
						</div>
					</div>
					<div className="benefit invert">
						<img
							data-sal="fade"
							data-sal-delay="800"
							data-sal-duration="1000"
							data-sal-easing="ease-in-out"
							src={aboutUsProfessionalAdviceImage}
							alt="About us"
						/>
						<div className="benefit-content">
							<h3>
								Professional <br /> advice
							</h3>
							<p>
								We ensure that our advisers are highly skilled professionals who are backed by stringent education standards. We provide
								ongoing educational and technical training, on top of the industry standards, through several forums e.g. webinars, face to
								face & online. With regular reviews of your financial plans being an integral part of NEO’s service, we ensure that your
								plans are matching the ever-changing industry requirements. We believe in being pro-active rather than reactive.
							</p>
							<ul className="benefit-list">
								<li>Initial Pre-Vetting</li>
								<li>Ongoing Education & Technical</li>
								<li>Regular Compliance Checks</li>
								<li>Bi-Annual Professional Development Days</li>
								<li>Highly cost-effective, vetting & paraplanning solution available</li>
							</ul>
						</div>
					</div>
				</Container>
			</StyledThirdSection>
			<StyledFourthSection>
				<Container>
					<div className="partner-content">
						<img
							data-sal="fade"
							data-sal-delay="250"
							data-sal-duration="1000"
							data-sal-easing="ease-in-out"
							src={aboutUsTailoredFinancialPlanningImage}
							alt="About us"
						/>
						<h3>
							A partner that supports your <br />
							<span>GROWTH.</span>
						</h3>

						<div className="chat-buttons">
							<Button to="/contact" value="Send us a message" colorScheme="light" size="big" className="send-us-a-message-btn" />
							<Button href="tel:+610894447494" value="Call us (08) 9444 7494" colorScheme="light" size="big" className="call-us-btn" />
						</div>
					</div>
					<section className="support-to-financial-planners">
						<h4>
							Support to <br /> financial planners
						</h4>
						<p>
							Our primary focus is to provide our financial planners with the tools and services, which enable them to grow their businesses
							and do what they do best – provide high quality financial planning advice, tailored to their client’s specific situation. All
							backed by market leading technology.
						</p>
						<h5>As part of our offer to planners we provide:</h5>
						<ul>
							<li>The opportunity to promote their business under their brand along with the business identity of NEO;</li>
							<li>High-quality local and Head Office support;</li>
							<li>
								A comprehensive range of services - <br /> from business development and marketing, to investment research and technical
								advice;
							</li>
							<li>Ongoing professional development training;</li>
							<li>Compliance and paraplanning support;</li>
							<li>Competitive licensee fees;</li>
							<li>The opportunity to specialise in specific areas such as direct share investment and salary packaging; and</li>
							<li>Access to more financial products from leading fund managers and insurance companies.</li>
						</ul>
					</section>
				</Container>
			</StyledFourthSection>
		</PageBase>
	);
}

const StyledFirstSection = styled.section`
	background-color: #f8f9fb;
	overflow-x: hidden;
	h3 {
		margin-top: 25px;
		text-align: center;
		color: #192851;
		font-size: 32px;
		font-weight: 700;
		line-height: 71px;
	}

	p {
		color: #49667b;
		font-size: 14px;
		font-weight: 400;
		text-align: center;
		line-height: 1.79;
		margin-bottom: 20px;
	}

	img {
		width: 100%;
		height: auto;
		position: relative;
		left: 6%;
		z-index: 1;
	}

	@media screen and (min-width: 1270px) {
		& .about-us img {
			width: 50%;
			left: 13%;
			top: 0;
		}
	}

	@media screen and (min-width: 768px) {
		& .about-us {
			display: flex;
			flex-direction: row;
			overflow-x: hidden;

			& .container {
				align-items: center;
				margin: 0 auto;
				justify-content: space-between;

				h3 {
					font-size: 46px;
					text-align: left;
					margin-bottom: 24px;
					margin-top: 0;
				}

				p {
					text-align: left;
					max-width: 480px;
					font-size: 15px;
				}
			}

			img {
				width: 50%;
				left: 13%;
				top: 0;
			}
		}
	}
`;

// image has 5px white gutter at the bottom

const StyledSecondSection = styled.section`
	padding: 50px 0;
	background-color: #004085;

	h3 {
		text-align: center;
		font-size: 25px;
		font-weight: 800;
		color: white;
	}

	& .chat-buttons > div {
		display: flex;
	}

	.send-us-a-message-btn {
		margin-top: 25px;
	}

	.send-us-a-message-btn,
	.call-us-btn {
		text-align: center;
		margin-bottom: 23px;

		a {
			color: white;
			text-decoration: none;
			font-weight: 700;
		}
	}

	@media screen and (min-width: 450px) {
		.chat-buttons {
			& > div {
				display: flex;
				justify-content: center;
				flex-direction: column;
				align-items: center;
			}
		}
	}

	@media screen and (min-width: 768px) {
		padding: 25px 0;
		& .container {
			justify-content: space-around;
			align-items: center;

			& .chat-buttons {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				& > div {
					margin: 0;
				}

				& .call-us-btn {
					margin-top: 30px;
				}
			}
		}
	}

	@media screen and (min-width: 992px) {
		padding: 25px 0;
		& .container {
			justify-content: space-between;
			align-items: center;

			& .chat-buttons {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;

				& > div {
					margin: 0;
				}

				& .call-us-btn {
					margin-left: 15px;
					margin-top: 0;
				}
			}
		}
	}
`;

const StyledThirdSection = styled.section`
	padding: 50px 0;
	div {
		margin-bottom: 15px;

		&.benefit-header {
			text-align: center;
			padding: 80px 0;

			h2 {
				font-size: 51px;
				line-height: 59px;
				color: #823fd3;
				font-weight: 700;
			}

			p {
				font-size: 15px;
				color: #49667b;
				line-height: 25px;
				max-width: 70%;
				margin: 25px auto 0 auto;
			}
		}

		& .why-choose-neo-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-top: 25px;

			p {
				text-align: left;
				font-size: 15px;
				color: #49667b;
				line-height: 25px;
			}

			@media screen and (min-width: 768px) {
				flex-direction: row;
				align-items: flex-start;
				justify-content: space-between;

				p {
					max-width: 40%;
				}
			}
		}

		& .benefit-list-label {
			text-transform: uppercase;
			color: #192851;
			font-weight: 700;
			font-size: 16px;
			line-height: 25px;
		}

		& .benefit-list {
			list-style: none;
			margin-top: 25px;
			li {
				font-size: 14px;
				color: #2b4659;
				margin: 7.5px 0;
				line-height: 20px;
				&:before {
					content: '-';
					color: #42ceff;
					margin-right: 11px;
				}
			}
		}

		img {
			margin: 0 auto;
			display: block;
			width: 100%;
			height: auto;
		}

		h3 {
			color: #192851;
			font-size: 28px;
			font-weight: 900;
			line-height: 32px;
			margin-top: 35px;
		}

		p {
			margin-top: 35px;
			color: #49667b;
			font-size: 14px;
			line-height: 25px;
		}
	}

	@media screen and (min-width: 768px) {
		& .container {
			flex-direction: column;

			& > .benefit {
				display: flex;
				align-items: center;
				justify-content: space-between;
				& .benefit-content {
					h3 {
						font-size: 46px;
						line-height: 51px;
					}
					p {
						font-size: 15px;
						max-width: 480px;
					}
				}

				img {
					width: 35%;
					margin: 0 !important;
				}
			}
			& .invert {
				flex-direction: row-reverse !important;
			}
		}
	}
`;

const StyledFourthSection = styled.section`
	background-color: #f8f9fb;
	padding: 50px 0;

	img {
		display: block;
		margin: 0 auto;
	}

	& .chat-buttons {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	h3 {
		margin-top: 25px;
		color: #192851;
		font-size: 19px;
		font-weight: 700;
		text-align: center;
		line-height: 41px;

		span {
			text-transform: uppercase;
			font-size: 42px;
			font-weight: 800;
		}
	}

	.send-us-a-message-btn {
		margin-top: 25px;
	}

	.send-us-a-message-btn,
	.call-us-btn {
		text-align: center;
		margin-bottom: 23px;
		display: flex;

		a {
			color: white;
			text-decoration: none;
			font-weight: 700;
		}
	}

	section.support-to-financial-planners {
		h4 {
			font-size: 28px;
			font-weight: 800;
			color: #192851;
			line-height: 32px;
			margin-top: 80px;
		}

		h5 {
			display: none;
		}

		p {
			margin-top: 25px;
			font-size: 14px;
			color: #49667b;
			line-height: 25px;
		}

		ul {
			list-style: none;
			margin-top: 25px;

			li {
				color: #2b4659;
				font-size: 13px;
				line-height: 20px;
				font-weight: 400;
				position: relative;
				left: 11px;
				margin: 15px 0;

				&::before {
					content: '-';
					margin-left: -11px;
					margin-right: 5.5px;
					color: #42ceff;
				}
			}
		}
	}

	@media screen and (min-width: 1500px) {
		& .chat-buttons {
			flex-direction: row !important;

			& .call-us-btn {
				margin: 0 0 0 20px;
			}
		}
	}

	@media screen and (min-width: 768px) {
		.partner-content {
			margin-right: 15px !important;
		}
	}

	@media screen and (min-width: 992px) {
		padding-top: 80px;
		& .container {
			align-items: flex-start;
			& .partner-content {
				width: 50%;

				h3 {
					font-size: 29px;
					line-height: 71px;
					span {
						font-size: 66px;
					}
				}

				img {
					width: 80%;
				}

				& .chat-buttons {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					margin-top: 25px;
				}
			}

			& .support-to-financial-planners {
				width: 50%;

				h4 {
					margin-top: 0;
					font-size: 46px;
					line-height: 51px;
				}

				h5 {
					display: block;
					font-size: 16px;
					color: #192851;
					line-height: 25px;
					font-weight: 700;
					text-transform: uppercase;
					margin-top: 25px;
				}
			}
		}
	}
`;

// TODO: FIND WAY TO ADD "-" BEFORE LIST ITEM ONLY ON FIRST LINE
